import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import OfficeSuppliesImg from "../../components/images/promotional/office-supplies"

export default function OfficeSupplies() {
    return (
        <>
            <Layout>
                <SEO title="Office Supplies" />
                <Display
                    title="Office Supplies"
                    description="Keep your organization running smoothly and make sure you have branded office supplies that do ‘double duty’ by promoting your company at the same time."
                    prev="/promotional/health-safety"
                    next="/promotional/promo-items"
                    image={<OfficeSuppliesImg />}
                />
            </Layout>                
        </>
    )
}
